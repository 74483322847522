// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cawaena-angular-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$cawaena-angular-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cawaena-angular-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cawaena-angular-theme: mat.m2-define-light-theme((color: (primary: $cawaena-angular-primary,
        accent: $cawaena-angular-accent,
        warn: $cawaena-angular-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cawaena-angular-theme);

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "/node_modules/flag-icons/css/flag-icons.min.css";
/*
------------------ Dark theme and light theme -------------------
Reference: https://blog.angulartraining.com/how-to-implement-a-dark-theme-with-css-and-angular-2cfd98b9455d
*/

// body {
//     --text-color: #222;
//     --bkg-color: #fff;
//     --btn-text-color: white;
//     --btn-bg-color: rgb(11,88,160);
// }

// body.dark-theme {
//     --text-color: #eee;
//     --bkg-color: #252222;
//     --btn-text-color: rgb(46,43,43);
//     --btn-bg-color: rgb(20,211,195);
// }

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'),
    url('/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
}

// body {
//     --dark-bg: #000000 0% 0% no-repeat padding-box;
//     --text-color: #eee;
//     --bkg-color: #252222;
//     --btn-text-color: rgb(46,43,43);
//     --btn-bg-color: rgb(20,211,195);
// }

// body{
//     background: var(--dark-bg);
// }

// h1, h2, h3, h5, p, a,span, li, table, small {
//     letter-spacing: 0px;
//     color: #FFFFFF;
// }

.long-blue-btn {
  background: #008CFF;
  border-radius: 24px;
  color: white;
  font: normal normal bold 17px/20px "Proxima Nova", "sans-serif";
  padding: 12px 20px;
}


/*
-----------------------------
privacy policy & Imprint start
-----------------------------
*/
.privacy-main-div {
  padding: 100px calc((100% - 1000px)/2) 50px;
}

.privacy-main-heading {
  font: italic normal bold 50px/48px "Times New Roman";
}

.privacy-sub-headiing,
.imprint-sub-heading {
  font: normal normal bold 18px/24px "Roboto", "sans-serif";
}

.privacy-main-div p,
.privacy-main-div li,
.privacy-main-div td,
.privacy-main-div th,
.imprint-main-div p,
.imprint-main-div li {
  font: normal normal normal 18px/24px "Roboto", "sans-serif";
}

.privacy-main-div table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}

.privacy-main-div td {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
  border: 1px solid;
  word-break: normal;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.privacy-main-div tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.privacy-main-div tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}


@media only screen and (max-width: 1200px) {

  .privacy-main-div {
    padding: 100px 20px;
  }

  .privacy-main-heading {
    font: italic normal bold 110px/108px "Times New Roman";
  }

  .privacy-main-div p,
  .privacy-main-div li,
  .privacy-main-div td,
  .privacy-main-div th {
    font: normal normal normal 44px/50px "Roboto", "sans-serif";
  }

  .privacy-sub-headiing,
  .imprint-sub-heading {
    font: normal normal bold 44px/50px "Roboto", "sans-serif";
  }

}

/*
  -----------------------------
  privacy policy & Imprint ends
  -----------------------------
  */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.waterwheel-carousel {
  img.carousel-center {
    top: 0px !important;
    height: 587px !important;
    width: 306px !important;
    left: 50% !important;
    transform: translateX(-50%);
    filter: none;
  }

  img {
    filter: blur(4px);
    top: 112px !important;
  }
}

.faq-carousel {
  .carousel-inner {
    height: 216px;
    width: calc(100% - 80px);
    margin: auto;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 40px;
    height: 40px;
    margin: auto;
    background: #474747;
    border-radius: 50%;
    color: #FFFFFF;
    opacity: 1;

    span {
      width: 20px;
      height: 24px;
    }
  }

  .carousel-item-next,
  .carousel-item-prev {
    height: 216px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }

  .carousel-item.active {
    height: 216px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }

  .carousel-indicators {
    button {
      position: relative;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid white;
      opacity: 1;
      margin: -1px 4px 0;
    }

    button.active {
      background: #008CFF;
      border: none;
      position: relative;
      width: 8px;
      height: 8px;
      margin: 0 6px;
    }

    button.active::after {
      content: '';
      border: 1px solid #008CFF;
      display: block;
      position: absolute;
      padding: 6px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}

// .terms-of-use-main-div {

//   h1 {
//     font: bold normal normal 24px/36px "Roboto", "sans-serif" !important;

//     span:first-child {
//       margin-right: 8px;
//     }
//   }

//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font: bold normal normal 20px/30px "Roboto", "sans-serif" !important;

//     span:first-child {
//       margin-right: 4px;
//     }
//   }

//   p,
//   ul,
//   li {
//     font: normal normal normal 16px/24px "Roboto", "sans-serif" !important;
//   }
// }

.legal-content-div {
  h1 {
    font: bold normal normal 20px/26px "Roboto", "sans-serif" !important;
    font-family: Roboto, "Helvetica Neue", sans-serif;

  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font: bold normal normal 18px/24px "Roboto", "sans-serif" !important;
  }

  p,
  ul,
  li {
    font: normal normal normal 18px/24px "Roboto", "sans-serif" !important;
  }

  table {
    width: 100% !important;
  }

  table,
  th,
  td,
  tr {
    border: 1px solid #FFFFFF !important;
  }
}

@media only screen and (max-width: 600px) {
  .faq-carousel {
    padding: 0 16px;

    .carousel-item.active {
      .faq-topic-card {
        border-color: #008CFF !important;
        background-color: rgba(0, 144, 255, 0.15);

        span,
        div {
          color: #008CFF !important;
        }
      }
    }
  }

  .waterwheel-carousel {
    img.carousel-center {
      height: 580px !important;
      width: 300px !important;
    }
  }
}

.faq-accordion {
  .accordion-item {
    border: none;
    border-bottom: 1px solid #FFFFFF;
    background-color: transparent;
    border-radius: 0;
  }

  .faq-accordion-header {
    box-shadow: none;

    .accordion-button:not(.collapsed) {
      background-color: transparent;
      color: #FFFFFF;
    }

    .accordion-button:focus {
      box-shadow: none;
    }

    .accordion-button {
      border: none;
      border-radius: 0 !important;
      box-shadow: none;
      font-weight: bold;
      background-color: transparent;
      color: #FFFFFF;
    }

    .accordion-button::after {
      content: none;
    }
  }

  .accordion-body {
    background-color: transparent;
    font-size: 14px;
    color: #FFFFFF;
    text-align: justify;
  }
}

.ios-coming-soon {
  filter: blur(1px);
  -webkit-filter: blur(2px);
  cursor: no-drop;
}

.loader-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #195FA9;
}

/*
-----------------------------
Mission starts
-----------------------------
*/
.italic-paragraph {
  font: italic normal normal 18px/24px "Roboto", "sans-serif";
}

.times-paragraph {
  font: italic normal bold 24px/25px "Times New Roman";
}

.special-text-width {
  width: 372px;
}

.mission-main-container {
  padding: 150px calc((100% - 1000px)/2) 50px;
  font: normal normal normal 18px/24px "Roboto", "sans-serif";
}

.fs-18 {
  font-size: 18px;
}

@media (max-width: 600px) {
  .mission-main-container {
      padding: 150px 20px 50px;
  }

  .special-text-width {
      width: auto;
  }
}

/*
-----------------------------
Mission ends
-----------------------------
*/